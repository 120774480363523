import { IProductGeneric, IProductListItem, ProductItem } from '../types/api';

const encodeUrl = (text: string) =>
  encodeURIComponent(text.toLowerCase().replace(/ |,|'|!|#|\?|\/|&/g, '-'));
// TODO: seems we can remove this now
// const getProductSlug = (productData: ProductItem) => encodeUrl(productData.model)
// const getBrandSlug = (productData: ProductItem) => encodeUrl(productData.brand.name)
export const getProductSlug = (productData: IProductListItem | ProductItem) =>
  encodeUrl(productData.brand.name).replace(/-{2,}/g, '-') +
  '-' +
  encodeUrl(productData.model) +
  '_' +
  encodeURIComponent(productData.sku);

const availableStatus = [1, 2, 3, 8, 9, 13];
export const isProductAvailable = (productData: IProductGeneric) =>
  availableStatus.includes(productData?.product_status?.status);
