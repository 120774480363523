import { isProductAvailable } from '@/lib/data-transformer/productData';
import { LOCALES } from '@/lib/data/domains';
import { getDomainByLang } from '@/lib/server/getDomains';
import { ProductItem } from '@/lib/types/api';
import { isString } from 'lodash-es';
import { translateUrl } from 'next-translate-routes-multi-domain';
import useTranslation from 'next-translate/useTranslation';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { getCategoryUrl } from '@/lib/data-transformer/gindumacUtils';

export default function Seo(props: {
  machineId?: string;
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
  product?: ProductItem;
}) {
  const router = useRouter();
  const { t } = useTranslation();
  const defaultMeta = {
    siteName: 'GINDUMAC',
    url: getDomainByLang(router.locale ?? 'en-US'),
    type: 'website',
    robots: 'follow, index',
    image:
      props.image ??
      props.product?.thumbnail.thumbnail_url ??
      props.product?.images?.[0]?.image_url ??
      '/facebook_image.jpg',
  };
  const meta = {
    ...defaultMeta,
  };
  const { title, description, keywords } = useTitle({
    title: props.title,
    description: props.description,
    keywords: props.keywords,
  });
  const { alts, canonical } = useAllLanguageAlternates({
    machine_id: props.machineId,
  });
  return (
    <Head>
      <title>{title}</title>
      <meta name='robots' content={'index,follow'} />
      <meta name='fragment' content='!' />
      <meta name='description' content={description} />
      {keywords && <meta name='keywords' content={keywords} />}
      <meta name='copyright' content='GINDUMAC' />
      <meta name='author' content='GINDUMAC' />
      <meta name='subject' content='Global Industrial Machinery Cluster' />
      <meta name='distribution' content='Global' />
      <meta property='og:url' content={`${meta.url}${router.asPath}`} />
      {alts.map((alt) => (
        <link
          key={alt.hrefLang}
          rel='alternate'
          hrefLang={alt.hrefLang}
          href={alt.href}
        />
      ))}
      {canonical && (
        <link
          rel='canonical'
          hrefLang={canonical.hrefLang}
          href={removeURLParametersExceptX(
            canonical.href,
            t('seo:QUERY_BRAND')
          )}
        />
      )}

      {/* Open Graph */}
      <meta property='og:type' content={meta.type} />
      <meta property='og:site_name' content={meta.siteName} />
      <meta property='og:description' content={description} />
      <meta property='og:title' content={title} />
      <meta name='image' property='og:image' content={meta.image} />
      {/* Twitter */}
      <meta name='twitter:site' content='@gindumac' />
      <meta name='twitter:creator' content='@gindumac' />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={meta.image} />

      <meta
        name='article:publisher'
        content='https://www.facebook.com/gindumac'
      />
      <meta name='article:author' content='https://www.facebook.com/gindumac' />

      <meta name='msapplication-TileColor' content='#ffffff' />
      <meta
        name='msapplication-TileImage'
        content='/favicons/ms-icon-144x144.png'
      />
      <meta name='theme-color' content='#ffffff' />
      <meta name='apple-mobile-web-app-title' content='GINDUMAC' />
      <meta name='application-name' content='GINDUMAC' />
      {/* {meta.date && (
        <>
          <meta property='article:published_time' content={meta.date} />
          <meta
            name='publish_date'
            property='og:publish_date'
            content={meta.date}
          />
        </>
      )} */}
      {/* Favicons */}
      {favicons.map((linkProps) => (
        <link key={linkProps.href} {...linkProps} />
      ))}
      <meta name='msapplication-TileColor' content='#ffffff' />
      <meta
        name='msapplication-TileImage'
        content='/favicons/ms-icon-144x144.png'
      />
      <meta name='theme-color' content='#ffffff' />
      {props.product && router.locale && (
        <GoogleShoppingData
          product={props.product}
          locale={router.locale}
          t={t}
        />
      )}
    </Head>
  );
}

/* Google Structured Data - For Google Shooping list */
function GoogleShoppingData({
  product,
  locale,
  t,
}: {
  product: ProductItem;
  locale: string;
  t: (key: string) => string;
}) {
  if (!isProductAvailable(product)) {
    return null;
  }
  const category_name = product.machine_categories[0].name;
  const category_translated = t(`products:${category_name}`);
  const domain = getDomainByLang(locale);
  const products_link = translateUrl('/products', locale);
  const product_link = translateUrl(getCategoryUrl(category_name), locale);
  const structuredData: any = {
    '@context': 'https://schema.org/',
    '@graph': [
      {
        '@type': 'Product',
        name: product.name,
        description: product.description,
        offers: {
          '@type': 'Offer',
          priceCurrency: product.currency,
          price: product.price,
          itemCondition: 'https://schema.org/UsedCondition',
          availability: isProductAvailable(product)
            ? 'https://schema.org/InStock'
            : 'https://schema.org/OutOfStock',
        },
        brand: {
          '@type': 'Brand',
          name: product.brand.name,
        },
      },
      {
        '@type': 'BreadcrumbList',
        itemListElement: [
          [
            {
              '@type': 'ListItem',
              position: 1,
              name: 'GINDUMAC',
              item: domain,
            },
            {
              '@type': 'ListItem',
              position: 2,
              name: t('products:PRODUCTS_NAME'),
              item: domain + products_link,
            },
            {
              '@type': 'ListItem',
              position: 3,
              name: category_translated,
              item: domain + product_link,
            },
            {
              '@type': 'ListItem',
              position: 4,
              name: product.seo_title,
            },
          ],
        ],
      },
    ],
  };
  if (product.sku) {
    structuredData['@graph'][0].sku = product.sku;
  }
  if (product.images) {
    structuredData['@graph'][0].image = product.images.map(
      (image) => image.image_url
    );
  }
  return (
    <script
      type='application/ld+json'
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(structuredData, null, 2),
      }}
    />
  );
}

function removeURLParametersExceptX(url: string, exception = 'brands') {
  const urlObj = new URL(url);
  const searchParams = new URLSearchParams(urlObj.search);

  // Get the value of the "brands" parameter
  const brandsParam = searchParams.get(exception);

  // Clear all existing parameters
  searchParams.delete(exception);
  searchParams.forEach((_, param) => searchParams.delete(param));

  // Add back the "brands" parameter if it exists
  if (brandsParam !== null) {
    searchParams.set(
      exception,
      isString(brandsParam) ? brandsParam.split('_')[0] : brandsParam
    );
  }

  urlObj.search = searchParams.toString();
  return urlObj.toString();
}

function useTitle({
  title,
  description,
  keywords,
}: {
  title?: string;
  description?: string;
  keywords?: string;
}) {
  const { t } = useTranslation();
  const { pathname } = useRouter();
  let response = {
    title: 'GINDUMAC',
    description: t('seo:PRODUCTS-description'),
    keywords: t('seo:PRODUCTS-keywords'),
  };
  const GINDUMAC_POSTFIX = ' | GINDUMAC';

  switch (pathname) {
    case '/':
      response = {
        title: t('seo:HOME-title'),
        description: t('seo:HOME-description'),
        keywords: t('seo:HOME-keywords'),
      };
      break;
    case '/products':
      response = {
        title: t('seo:PRODUCTS-title'),
        description: t('seo:PRODUCTS-description'),
        keywords: t('seo:PRODUCTS-keywords'),
      };
      break;
    case '/products/used-machine-tools':
      response = {
        title: t('seo:PRODUCTS_MACHINE_TOOLS_TITLE'),
        description: t('seo:PRODUCTS_MACHINE_TOOLS_DESCRIPTION'),
        keywords: t('seo:PRODUCTS_MACHINE_TOOLS_KEYWORDS'),
      };
      break;
    case '/products/used-sheet-metal-machinery':
      response = {
        title: t('seo:PRODUCTS_SHEET_METAL_MACHINERY_TITLE'),
        description: t('seo:PRODUCTS_SHEET_METAL_MACHINERY_DESCRIPTION'),
        keywords: t('seo:PRODUCTS_SHEET_METAL_MACHINERY_KEYWORDS'),
      };
      break;
    case '/products/used-plastics-processing-machinery':
      response = {
        title: t('seo:PRODUCTS_PLASTICS_PROCESSING_MACHINERY_TITLE'),
        description: t(
          'seo:PRODUCTS_PLASTICS_PROCESSING_MACHINERY_DESCRIPTION'
        ),
        keywords: t('seo:PRODUCTS_PLASTICS_PROCESSING_MACHINERY_KEYWORDS'),
      };
      break;
    case '/products/used-automation-equipment':
      response = {
        title: t('seo:PRODUCTS_AUTOMATION_EQUIPMENT_TITLE'),
        description: t('seo:PRODUCTS_AUTOMATION_EQUIPMENT_DESCRIPTION'),
        keywords: t('seo:PRODUCTS_AUTOMATION_EQUIPMENT_KEYWORDS'),
      };
      break;
    case '/products/used-wood-working-machinery':
      response = {
        title: t('seo:PRODUCTS_WOOD_WORKING_MACHINERY_TITLE'),
        description: t('seo:PRODUCTS_WOOD_WORKING_MACHINERY_DESCRIPTION'),
        keywords: t('seo:PRODUCTS_WOOD_WORKING_MACHINERY_KEYWORDS'),
      };
      break;
    case '/who-we-are':
      response = {
        title: t('seo:ABOUT_US-title'),
        description: t('seo:ABOUT_US-description'),
        keywords: t('seo:ABOUT_US-keywords'),
      };
      break;
    case '/we-buy-your-project':
      response = {
        title: t('seo:BUY_YOUR_PROJECT-title') + GINDUMAC_POSTFIX,
        description: t('seo:BUY_YOUR_PROJECT-description'),
        keywords: t('seo:BUY_YOUR_PROJECT-keywords'),
      };
      break;
    case '/careers':
      response = {
        title: t('seo:CAREERS_LIST-title'),
        description: t('seo:CAREERS_LIST-description'),
        keywords: t('seo:CAREERS_LIST-keywords'),
      };
      break;
    case '/financing-options':
      response = {
        title: t('seo:CORPORATE_FINANCING_OPTIONS-title'),
        description: t('seo:CORPORATE_FINANCING_OPTIONS-description'),
        keywords: t('seo:CORPORATE_FINANCING_OPTIONS-keywords'),
      };
      break;
    case '/general-terms-and-conditions-of-purchase':
      response = {
        title: t('seo:TERMS_AND_CONDITIONS-title'),
        description: t('seo:TERMS_AND_CONDITIONS-description'),
        keywords: t('seo:TERMS_AND_CONDITIONS-keywords'),
      };
      break;
    case '/general-terms-and-conditions-of-sale':
      response = {
        title: t('seo:TERMS_AND_CONDITIONS-title'),
        description: t('seo:TERMS_AND_CONDITIONS-description'),
        keywords: t('seo:TERMS_AND_CONDITIONS-keywords'),
      };
      break;
    case '/cookies':
      response = {
        title: t('seo:COOKIES-title'),
        description: t('seo:COOKIES-description'),
        keywords: t('seo:COOKIES-keywords'),
      };
      break;
    case '/legal':
      response = {
        title: t('seo:LEGAL-title'),
        description: t('seo:LEGAL-description'),
        keywords: t('seo:LEGAL-keywords'),
      };
      break;
    case '/privacy':
      response = {
        title: t('seo:PRIVACY-title'),
        description: t('seo:PRIVACY-description'),
        keywords: t('seo:PRIVACY-keywords'),
      };
      break;
    case '/logistics-services':
      response = {
        title: t('seo:CORPORATE_LOGISTICS_SERVICES-title'),
        description: t('seo:CORPORATE_LOGISTICS_SERVICES-description'),
        keywords: t('seo:CORPORATE_LOGISTICS_SERVICES-keywords'),
      };
      break;
    case '/machine-categories':
      response = {
        title: t('seo:CORPORATE_MACHINE_CATEGORIES-title'),
        description: t('seo:CORPORATE_MACHINE_CATEGORIES-description'),
        keywords: t('seo:CORPORATE_MACHINE_CATEGORIES-keywords'),
      };
      break;
    case '/premium-inspection':
      response = {
        title: t('seo:CORPORATE_PREMIUM_INSPECTION-title'),
        description: t('seo:CORPORATE_PREMIUM_INSPECTION-description'),
        keywords: t('seo:CORPORATE_PREMIUM_INSPECTION-keywords'),
      };
      break;
    case '/retec':
      response = {
        title: t('seo:RETEC-title'),
        description: t('seo:RETEC-description'),
        keywords: t('seo:RETEC-keywords'),
      };
      break;
    case '/trade-fairs':
      response = {
        title: t('seo:CORPORATE_TRADE_FAIRS-title'),
        description: t('seo:CORPORATE_TRADE_FAIRS-description'),
        keywords: t('seo:CORPORATE_TRADE_FAIRS-keywords'),
      };
      break;
    case '/selling-process':
    case '/selling-process/step-1':
    case '/selling-process/step-2':
    case '/selling-process/step-3':
    case '/selling-process/step-4':
      response = {
        title: t('seo:SELLING_PROCESS_STEP1-title'),
        description: t('seo:SELLING_PROCESS_STEP1-description'),
        keywords: t('seo:SELLING_PROCESS_STEP1-keywords'),
      };
      break;
  }
  if (title) {
    response.title = title;
  }
  if (description) {
    response.description = description;
  }
  if (keywords) {
    response.keywords = keywords;
  }
  return response;
}

interface LanguageAlternate {
  hrefLang: string;
  href: string;
}

function useAllLanguageAlternates({ machine_id }: { machine_id?: string }) {
  const { locale: currentLocale, pathname } = useRouter();
  const i18n: LanguageAlternate[] = useMemo(() => {
    const urls: LanguageAlternate[] = [];
    LOCALES.forEach((lang) => {
      try {
        const translatedPath = translateUrl(
          machine_id
            ? {
                pathname,
                query: { machine_id: machine_id + '' },
              }
            : pathname,
          lang == 'x-default' ? 'en-US' : lang,
          {
            withoutLangPrefix: true,
            format: 'string',
          }
        );
        urls.push({
          hrefLang: lang,
          href: getDomainByLang(lang) + translatedPath,
        });
        if (lang === 'en-US') {
          urls.push({
            hrefLang: 'x-default',
            href: getDomainByLang(lang) + translatedPath,
          });
        }
      } catch (e) {
        console.log('error', e);
      }
    });
    return urls;
  }, [machine_id, pathname]);

  const { alts, canonical } = useMemo(() => {
    const canonical = i18n.find((item) => item.hrefLang === currentLocale);
    const alts = i18n.filter((item) => item.hrefLang !== currentLocale);
    return { alts, canonical };
  }, [i18n, currentLocale]);

  return { alts, canonical };
}

type Favicons = {
  rel: string;
  href: string;
  sizes?: string;
  type?: string;
};

// !STARTERCONF this is the default favicon, you can generate your own from https://www.favicon-generator.org/ then replace the whole /public/favicon folder
const favicons: Array<Favicons> = [
  {
    rel: 'apple-touch-icon',
    sizes: '57x57',
    href: '/favicons/apple-icon-57x57.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '60x60',
    href: '/favicons/apple-icon-60x60.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '72x72',
    href: '/favicons/apple-icon-72x72.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '76x76',
    href: '/favicons/apple-icon-76x76.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '114x114',
    href: '/favicons/apple-icon-114x114.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '120x120',
    href: '/favicons/apple-icon-120x120.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '144x144',
    href: '/favicons/apple-icon-144x144.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '152x152',
    href: '/favicons/apple-icon-152x152.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/favicons/apple-icon-180x180.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '192x192',
    href: '/favicons/android-icon-192x192.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '/favicons/favicon-32x32.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '96x96',
    href: '/favicons/favicon-96x96.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: '/favicons/favicon-16x16.png',
  },
  {
    rel: 'manifest',
    href: '/favicons/manifest.json',
  },
];
